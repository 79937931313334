import type { PropsWithChildren } from "react";
import 배경_이미지 from "/public/assets/mobile/images/pic/img_main_section5.webp";
import { 마켓봄_로고_Desktop } from "../components/마켓봄_로고_Desktop";
import { NotoSans } from "../components/font";

export const MobileLayout = ({ children }: PropsWithChildren) => {
  return (
    <>
      <style jsx global>{`
        html,
        body,
        div,
        span,
        applet,
        object,
        iframe,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        blockquote,
        pre,
        a,
        abbr,
        acronym,
        address,
        big,
        cite,
        code,
        del,
        dfn,
        em,
        img,
        ins,
        kbd,
        q,
        s,
        samp,
        small,
        strike,
        strong,
        sub,
        sup,
        tt,
        var,
        b,
        u,
        i,
        center,
        dl,
        dt,
        dd,
        ol,
        ul,
        li,
        fieldset,
        form,
        label,
        legend,
        table,
        caption,
        tbody,
        tfoot,
        thead,
        tr,
        th,
        td,
        article,
        aside,
        canvas,
        details,
        embed,
        figure,
        figcaption,
        footer,
        header,
        hgroup,
        menu,
        nav,
        output,
        ruby,
        section,
        summary,
        time,
        mark,
        audio,
        video {
          margin: 0;
          padding: 0;
          border: 0;
          font-size: 100%;
          font: inherit;
          vertical-align: baseline;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
        }
        /* HTML5 display-role reset for older browsers */
        article,
        aside,
        details,
        figcaption,
        figure,
        footer,
        header,
        hgroup,
        menu,
        nav,
        section {
          display: block;
        }
        body {
          line-height: 1.5;
        }
        ol,
        ul {
          list-style: none;
        }
        blockquote,
        q {
          quotes: none;
        }
        blockquote:before,
        blockquote:after,
        q:before,
        q:after {
          content: "";
          content: none;
        }
        table {
          border-collapse: collapse;
          border-spacing: 0;
        }
        a {
          text-decoration: none;
          color: inherit;
        }
        button {
          border: 0;
          background: none;
          cursor: pointer;
        }
        input {
          border-radius: 0;
        }

        img {
          aspect-ratio: unset;
          height: auto;
        }
      `}</style>
      <style jsx global>
        {`
          @charset "utf-8";
          @media screen and (min-width: 992px) {
            .device-container {
              width: 450px !important;
              margin: auto auto !important;
              transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
            }
            .whole-wrapper {
              gap: 80px;
              width: fit-content !important;
              max-width: initial !important;
              transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
            }
            .mobile-description {
              display: flex !important;
            }
          }
          .content-container {
            overflow: scroll;
            height: 100%;
            max-height: 100dvh !important;
          }
          html {
            -webkit-text-size-adjust: 100%;
            background: #f3f5f9;
          }
          .whole-wrapper {
            display: flex;
            margin: 0px auto;
            width: 100%;
            max-width: 600px;
            transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
          }
          .device-container {
            background: #fff;
            max-width: 600px;
            border-radius: 0px;
            max-width: 600px;
            width: 100%;
            overflow: hidden;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
            transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
          }
          .mobile-description {
            display: none;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 200px;
            width: 240px;
          }
          #wrap {
            font-size: 14px;
            color: #000;
            font-family: ${NotoSans.style.fontFamily};
            line-height: 1.3;
            letter-spacing: -1px;
            overflow: scroll;
          }
          #wrap.scroll-fixed {
            overflow: hidden;
          }
          * {
            box-sizing: border-box;
            -webkit-overflow-scrolling: touch;
            -webkit-appearance: none;
            outline: none;
          }
          #wrap {
            position: relative;
            width: 100%;
            padding: 0;
            transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
          }
          .blind {
            position: absolute;
            overflow: hidden;
            clip: rect(0 0 0 0);
            margin: -1px;
            width: 1px;
            height: 1px;
          }
          .fr {
            float: right;
          }
          .fl {
            float: left;
          }
          .fn {
            float: none;
          }
          .cb:after {
            content: "";
            display: block;
            clear: both;
          }
          .bold {
            font-weight: bold;
          }
          .tl {
            text-align: left !important;
          }
          .tc {
            text-align: center !important;
          }
          .tr {
            text-align: right !important;
          }
          a:link {
            text-decoration: none;
          }
          a:visited {
            text-decoration: none;
          }
          a:hover {
            text-decoration: none;
          }
          button {
            outline: none;
          }
          input,
          textarea,
          select,
          button {
            font-family: ${NotoSans.style.fontFamily};
          }
          .m-show {
            display: none !important;
          }
          .table {
            display: table;
            width: 100%;
            height: 100%;
            text-align: center;
          }
          .table-cell {
            display: table-cell;
            vertical-align: middle;
          }
          .open-sans {
            font-family: "Open Sans", sans-serif;
          }
          .fc1 {
            color: #fe6b37 !important;
          }

          /* Button */
          .btn.type1 {
            display: inline-block;
            height: 24px;
            padding: 0 10px;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 24px;
            font-size: 10px;
            color: #333;
            line-height: 22px;
            text-align: center;
          }
          .btn.type2 {
            display: inline-block;
            height: 24px;
            padding: 0 10px;
            background: #fe6b37;
            border: 1px solid #fe6b37;
            border-radius: 24px;
            font-size: 10px;
            color: #fff;
            line-height: 22px;
            text-align: center;
          }
          .btn.type3 {
            display: block;
            width: 220px;
            height: 60px;
            background: #333;
            border-radius: 4px;
            font-size: 18px;
            color: #fff;
            text-align: center;
            line-height: 60px;
            transition: 0.2s ease-in-out;
          }
          .btn.type4 {
            display: inline-block;
            height: 24px;
            padding: 0 10px;
            background: #8461cf;
            border: 1px solid #8461cf;
            border-radius: 24px;
            font-size: 10px;
            color: #fff;
            line-height: 22px;
            text-align: center;
          }

          /* Dot list */
          .dot-list li {
            position: relative;
            padding-left: 8px;
            font-size: 12px;
            font-weight: 300;
            color: #333;
            line-height: 1.6;
          }
          .dot-list li:before {
            content: "";
            position: absolute;
            top: 8px;
            left: 0;
            width: 2px;
            height: 2px;
            background: #333;
            border-radius: 2px;
          }

          /* Layer */
          .layer {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
            width: 100%;
            max-width: 100vw;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            opacity: 0;
            /* pointer-events: none; */
          }
          .layer .on {
            opacity: 1;
            pointer-events: all;
            transition: all 0.3s ease-in;
          }
          .layer .middle-wrap {
            display: table;
            table-layout: fixed;
            width: 100%;
            height: 100%;
            text-align: center;
          }
          .layer .middle-wrap .middle {
            display: table-cell;
            vertical-align: middle;
          }
          .layer .content {
            position: relative;
          }
          /* .layer .btn-close {
      position: absolute;
      top: 14px;
      right: 14px;
      width: 24px;
      height: 24px;
      background: url('/public/assets/mobile/images/btn/btn_close2_b.svg') no-repeat center center;
      text-indent: -9999em;
  } */
          .layer .btn-close:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: scale(1.5, 1.5);
            pointer-events: none;
          }
          .layer-video .content {
            width: 100%;
            padding-bottom: 14px;
            background: #fff;
          }
          .layer-video .content .title {
            overflow: hidden;
            height: 34px;
            padding: 8px 39px 0 10px;
            font-size: 14px;
            font-weight: 300;
            color: #242426;
            text-align: left;
            line-height: 20px;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .layer-video .btn-close {
            top: 6px;
            right: 6px;
          }
          .layer-ready .content {
            margin: 0 30px;
            padding: 219px 0 64px;
            background: url("/public/assets/mobile/images/icon/icon_truck1.svg")
              no-repeat center 90px #fff;
            border-radius: 20px;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
          }
          .layer-ready .content dt {
            font-size: 16px;
            color: #333;
            text-align: center;
            line-height: 18px;
          }
          .layer-ready .content dt span {
            color: #fe6b37;
          }
          .layer-ready .content dd {
            font-size: 14px;
            color: #333;
            text-align: center;
            line-height: 18px;
          }
          .layer-term .flex {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            background: #f2f2f2;
          }
          .layer-term .top {
            flex: 0 1 52px;
            height: 52px;
            padding-top: 11px;
            background: #ff663f;
            border-bottom: 0;
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            text-align: center;
            line-height: 29px;
          }
          .layer-term .middle {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            padding: 20px 20px 0;
          }
          .layer-term .middle h2 {
            flex: 0 1 auto;
            font-size: 16px;
            font-weight: bold;
            color: #333;
            line-height: 40px;
          }
          .layer-term .middle .content {
            overflow-y: auto;
            flex: 1 1 0;
            padding: 20px;
            background: #fff;
          }
          .layer-term .middle .content pre {
            overflow: hidden;
            width: 100%;
            white-space: pre-wrap;
          }
          .layer-term .middle .content pre strong {
            font-weight: bold;
          }
          .layer-term .middle .content pre table {
            width: 100%;
            max-width: 100%;
            margin: 5px 0;
          }
          .layer-term .middle .content pre table th,
          .layer-term .middle .content pre table td {
            padding: 5px;
            border: 1px solid #000;
          }
          .layer-term .bottom {
            flex: 0 1 auto;
            margin-top: auto;
            padding: 20px;
          }
          .layer-term .bottom .btn-confirm {
            display: block;
            width: 100%;
            height: 40px;
            background: #999;
            font-size: 14px;
            font-weight: bold;
            color: #fff;
            text-align: center;
          }
          .layer-advantage .middle-wrap {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
          }
          .layer-advantage .middle {
            display: block;
            flex: 1 1 auto;
            overflow-y: auto;
            max-height: 100%;
            padding: 30px;
          }
          .layer-advantage .content {
            width: 100%;
            padding: 40px 20px 0;
            background: #fff;
            border-radius: 20px;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
          }
          .layer-advantage h2 {
            margin-bottom: 21px;
            padding-top: 30px;
            background: url("/public/assets/mobile/images/logo/logo4.svg")
              no-repeat center top;
            background-size: 50px 24px;
            font-size: 20px;
            font-weight: bold;
            color: #fe6b37;
            text-align: center;
            line-height: 24px;
          }
          .layer-advantage dl + dl {
            margin-top: 10px;
          }
          .layer-advantage dl dt {
            margin-bottom: 2px;
            font-size: 16px;
            font-weight: bold;
            color: #35206a;
            line-height: 18px;
          }
          .layer-advantage dl dd {
            font-size: 12px;
            color: #333;
            line-height: 18px;
          }
          .layer-advantage p + p {
            margin-top: 10px;
          }
          .layer-advantage p {
            font-size: 16px;
            font-weight: bold;
            color: #35206a;
            line-height: 18px;
          }
          .layer-advantage ol li + li {
            margin-top: 10px;
          }
          .layer-advantage ol li {
            font-size: 16px;
            font-weight: bold;
            color: #35206a;
            line-height: 18px;
          }
          .layer-advantage .phone {
            margin-top: 28px;
          }
          .layer-advantage .phone h3 {
            margin-bottom: 6px;
            font-size: 16px;
            font-weight: bold;
            color: #000;
            text-align: center;
            line-height: 20px;
          }
          .layer-advantage .phone .btn-call {
            display: block;
            width: 184px;
            height: 45px;
            margin: 0 auto;
            padding-top: 11px;
            background: #35206a;
            border-radius: 3px;
            font-size: 14px;
            font-weight: bold;
            color: #fff;
            text-align: center;
            line-height: 20px;
          }
          .layer-advantage .btn-wrap {
            height: 45px;
            margin: 23px -20px 0;
            border-top: 1px solid #ddd;
          }
          .layer-advantage .btn-wrapfter {
            content: "";
            display: block;
            clear: both;
          }
          .layer-advantage .btn-wrap button {
            float: left;
            width: 50%;
            height: 100%;
            font-size: 14px;
            color: #333;
          }
          .layer-advantage .btn-wrap button + button {
            border-left: 1px solid #ddd;
          }

          /* Form */
          .input-text label {
            display: block;
            margin-bottom: 2px;
            font-size: 14px;
            font-weight: 300;
            color: #333;
          }
          .input-text input {
            display: block;
            width: 100%;
            height: 30px;
            padding: 0 10px;
            background: #eee;
            border: 1px solid #eee;
            font-size: 12px;
            color: #000;
          }
          .input-text textarea {
            display: block;
            width: 100%;
            height: 110px;
            padding: 6px 10px;
            background: #eee;
            border: 1px solid #eee;
            border-radius: 0;
            font-size: 12px;
            color: #000;
            resize: none;
          }
          .input-text input:focus,
          .input-text textarea:focus {
            background: #fff;
            border-color: #333;
          }
          .input-text input::placeholder,
          .input-text textarea::placeholder {
            font-size: 12px;
            color: #ccc;
          }
          .input-text + .input-text {
            margin-top: 10px;
          }
          .input-checkbox input {
            position: absolute;
            overflow: hidden;
            clip: rect(0 0 0 0);
            margin: -1px;
            width: 1px;
            height: 1px;
          }
          .input-checkbox span {
            display: block;
            padding-left: 33px;
            background: url("/public/assets/mobile/images/icon/icon_checkbox_off1.svg")
              no-repeat left center;
            font-size: 14px;
            font-weight: bold;
            color: #676767;
            line-height: 20px;
          }
          .input-checkbox input:checked + span {
            background-image: url("/public/assets/mobile/images/icon/icon_checkbox_on1.svg");
          }

          /* 이벤트 배너 */

          .event-banner {
            position: relative;
            z-index: 11;
          }
          .event-banner a img {
            display: block;
            width: 100%;
          }
          .event-banner .tail {
            position: absolute;
            bottom: -11px;
            right: 54px;
            width: 16px;
          }

          .layer-alert #header {
            height: 45px;
          }
          .layer-alert #header .title {
            float: none;
            padding-top: 0;
          }
          .layer-alert #wrap {
            padding: 62px 2.5% 0;
          }

          /* =================================================
  * Container
================================================= */
          #container {
            overflow-x: hidden;
          }

          /* Main */
          // .main .section1 {
          //   position: relative;
          //   padding: 45px 0 77%;
          //   background-size: 100% auto;
          //   text-align: center;
          //   /* padding-bottom: 32px; */
          // }
          // .main .section1_sale {
          //   content: "";
          //   position: absolute;
          //   bottom: 5vw;
          //   right: 4vw;
          //   width: 85px;
          //   height: 85px;
          // }
          // .main .section1 h3 {
          //   margin-bottom: 10px;
          //   font-size: 14px;
          //   color: #000;
          //   letter-spacing: -0.03em;
          // }
          // .main .section1 h3 strong {
          //   font-weight: bold;
          // }
          // .main .section1 p {
          //   position: relative;
          //   margin-bottom: 20px;
          //   font-size: 30px;
          //   font-weight: bold;
          //   color: #000;
          //   line-height: 34px;
          //   letter-spacing: -0.03em;
          // }
          // .franchise .main .section1 p {
          //   margin-bottom: -24px;
          // }
          // .main .section1 p:before {
          //   content: "";
          //   position: absolute;
          //   bottom: -7px;
          //   left: 50%;
          //   width: 199px;
          //   height: 18px;
          //   background: rgba(254, 107, 56, 0.2);
          //   transform: translate(-50%, 0);
          // }
          // .franchise .main .section1 p:before {
          //   content: "";
          //   position: absolute;
          //   bottom: -7px;
          //   left: 50%;
          //   width: 286px;
          //   height: 18px;
          //   background: rgba(254, 107, 56, 0.2);
          //   transform: translate(-50%, 0);
          // }
          // .main .section1 .btn-start {
          //   display: block;
          //   width: 220px;
          //   height: 55px;
          //   margin: 0 auto;
          //   padding-top: 13px;
          //   background: #ed6434;
          //   border-radius: 45px;
          //   font-size: 20px;
          //   font-weight: bold;
          //   color: #fff;
          //   text-align: center;
          //   line-height: 27px;
          //   letter-spacing: -0.03em;
          // }
          .main .section2 {
          
            padding-top:35px;
            padding-bottom;30px;
            display:flex;
            flex-direction:column;
            align-items: center;
            background: #f3f5f9;
            text-align: center;
          }
          .main .section2 dl dt {
            margin-bottom: 9px;
            font-size: 22px;
            font-weight: bold;
            color: #333;
            text-align: center;
            line-height: 28px;
            letter-spacing: -2.15px;
          }
          .main .section2 dl dd {
            margin-bottom: 10px;
            font-size: 18px;
            color: #333;
            line-height: 24px;
            letter-spacing: -1.2px;
            text-align: center;
          }
          .main .section2 dl dd .highlight {
            display: inline-block;
            position: relative;
            margin-top: 10px;
          }
          .main .section2 dl dd .highlight:before {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 50%;
            z-index: 1;
            width: 100%;
            height: 12px;
            background: #7245b6;
            opacity: 0.2;
            transform: translate(-50%, 0);
          }
          .main .section2 dl dd .highlight p {
            position: relative;
            z-index: 2;
            font-size: 18px;
            font-weight: bold;
            color: #333;
            text-align: center;
            line-height: 24px;
          }
          .main .section2 dl dd .highlight p span {
            color: #402578;
          }
          .main .section2 dl dd .highlight p span em {
            font-style: italic;
          }
          .main .section2 .pic {
            position: relative;
          }
          .main .section2 .pic img {
            display: block;
            width: 100%;
          }
          .main .section2 .pic a {
            display: block;
            position: relative;
          }
          .main .section2 .pic button:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
            width: 34px;
            height: 34px;
            margin: -17px 0 0 -17px;
            background-size: 100% 100%;
          }
          .main .section3 {
            padding: 40px 20px 50px;
            text-align: center;
          }
          .main .section3 h3 {
            margin-bottom: 30px;
            font-size: 22px;
            font-weight: bold;
            color: #333;
            line-height: 28px;
            letter-spacing: -2.35px;
          }
          .main .section3 .white-box {
            padding: 30px 0 17px;
            background: #fff;
            border-radius: 10px;
            box-shadow: 2px 4px 10px 0 rgba(38, 29, 72, 0.2);
          }
          .main .section3 .white-box + .white-box {
            margin-top: 30px;
            padding-bottom: 21px;
          }
          .section3 .toggle-wrap {
            margin-top: 30px;
          }
          .section3 .btn-detail {
            display: block;
            width: 100%;
            max-width: 224px;
            height: 40px;
            margin: 30px auto 0;
            border: 1px solid #666;
            border-radius: 20px;
            font-size: 14px;
            font-weight: bold;
            color: #000;
            text-align: center;
            line-height: 20px;
            letter-spacing: -1.17px;
          }
          .main .section3 .white-box h4 {
            margin-bottom: 12px;
            font-size: 20px;
            font-weight: 500;
            color: #000;
            line-height: 26px;
            letter-spacing: -2.5px;
          }
          .main .section3 .white-box p {
            font-size: 15px;
            color: #000;
            line-height: 20px;
            letter-spacing: -1.5px;
          }
          :not(.toggle-wrap) > .main .section3 .white-box .pic {
            margin-top: 22px;
          }
          .main .section3 .white-box .pic img {
            display: block;
            width: 100%;
          }
          .main .section3 .white-box + .white-box .pic {
            margin-top: 22px;
          }
          .main .section3 .toggle-wrap {
            margin-top: 30px;
          }
          .main .section3 .btn-detail {
            display: block;
            width: 100%;
            max-width: 224px;
            height: 40px;
            margin: 30px auto 0;
            border: 1px solid #666;
            border-radius: 20px;
            font-size: 14px;
            font-weight: bold;
            color: #000;
            text-align: center;
            line-height: 20px;
            letter-spacing: -1.17px;
          }
          .main .section5 {
            padding: 40px 30px 50px;
            background: #f3f5f9;
          }
          .main .section5 h3 {
            margin-bottom: 20px;
            font-size: 22px;
            font-weight: bold;
            color: #333;
            text-align: center;
            line-height: 28px;
            letter-spacing: -2.15px;
          }
          .main .section5 .white-box {
            padding: 30px 0;
            background: #fff;
            border-radius: 10px;
            text-align: center;
          }
          .main .section5 .white-box .row + .row {
            margin-top: 20px;
          }
          .main .section5 .white-box h4 {
            margin-bottom: 14px;
            font-size: 20px;
            font-weight: 500;
            color: #000;
            text-align: center;
            line-height: 26px;
            letter-spacing: -2.1px;
          }
          .main .section5 .white-box a {
            display: block;
            max-width: 224px;
            height: 60px;
            margin: 0 auto;
            border: 1px solid #dce1eb;
            border-radius: 5px;
          }
          .main .section5 .white-box a + a {
            margin-top: 10px;
          }
          .main .section5 .white-box .btn-googleplay {
            padding-top: 7px;
          }
          .main .section5 .white-box .btn-googleplay img {
            display: block;
            width: 131px;
            margin: 0 auto;
          }
          .main .section5 .white-box .btn-appstore {
            padding-top: 12px;
          }
          .main .section5 .white-box .btn-appstore img {
            display: block;
            width: 135px;
            margin: 0 auto;
          }
          .main .section6 {
            padding: 40px 0 20vh;
            background-size: 100% auto;
            text-align: center;
          }
          .main .section6 h3 {
            margin-bottom: 39px;
            font-size: 22px;
            font-weight: bold;
            color: #333;
            line-height: 28px;
            letter-spacing: -2.15px;
          }
          .main .section6 dl + dl {
            margin-top: 30px;
          }
          .main .section6 dl dt {
            width: 200px;
            margin: 0 auto;
            padding-bottom: 10px;
            border-bottom: 1px solid #494949;
            font-size: 20px;
            font-weight: 500;
            color: #333;
            line-height: 1;
            letter-spacing: -1.5px;
          }
          .main .section6 dl dt span {
            display: inline-block;
            margin-left: 4px;
            font-size: 12px;
            font-weight: 400;
            vertical-align: top;
            line-height: 20px;
          }
          .main .section6 dl dd {
            font-size: 50px;
            font-weight: bold;
            color: #000;
            line-height: 74px;
          }
          .main .section7 {
            padding: 40px 30px 60px;
            text-align: center;
          }
          .main .section7 h3 {
            margin-bottom: 35px;
            font-size: 22px;
            font-weight: bold;
            color: #333;
            line-height: 28px;
            letter-spacing: -2.15px;
          }
          .main .section7 ul li + li {
            margin-top: 50px;
          }
          .main .section7 ul li .pic {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            height: 150px;
            margin-bottom: 20px;
            border-radius: 10px;
          }
          .main .section7 ul li .pic dl {
            width: 200px;
          }
          .main .section7 ul li .pic dt {
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #fff;
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            line-height: 1;
          }
          .main .section7 ul li .pic dd {
            font-size: 14px;
            color: #fff;
            line-height: 16px;
          }
          .main .section7 ul li .text dt {
            margin-bottom: 12px;
            font-size: 20px;
            font-weight: 500;
            color: #000;
            line-height: 26px;
            letter-spacing: -2.1px;
          }
          .main .section7 ul li .text dd {
            font-size: 15px;
            color: #000;
            line-height: 20px;
            letter-spacing: -1.5px;
          }
          .main .section8 .slider .item {
            margin: 0 30px;
            padding: 25px 38px 38px;
            background: #fff;
            border-radius: 10px;
          }
          .main .section8 .slider .item h4 {
            margin-bottom: 35px;
            font-size: 20px;
            font-weight: 500;
            color: #000;
            line-height: 24px;
            letter-spacing: -2.1px;
            word-break: keep-all;
          }
          .main .section8 .slider .item .date {
            display: block;
            margin-bottom: 8px;
            font-size: 15px;
            color: #000;
            line-height: 18px;
            letter-spacing: -1.12px;
          }
          .main .section8 .slider .item p {
            margin-bottom: 28px;
            font-size: 15px;
            color: #000;
            line-height: 18px;
            letter-spacing: -1.5px;
          }
          .main .section8 .slider .item .btn-detail {
            display: block;
            max-width: 224px;
            height: 40px;
            margin: 0 auto;
            padding-top: 8px;
            border: 1px solid #666;
            border-radius: 20px;
            font-size: 14px;
            font-weight: bold;
            color: #000;
            text-align: center;
            line-height: 20px;
            letter-spacing: -1.17px;
          }
          .main .section8 .slider .slick-dots {
            margin-top: 20px;
            font-size: 0;
          }
          .main .section8 .slider .slick-dots li {
            display: inline-block;
            margin: 0 6px;
            vertical-align: top;
            width: 8px;
            height: 8px;
          }
          .main .section8 .slider .slick-dots li button {
            display: block;
            width: 8px;
            height: 8px;
            padding: 0;
            background: #fff;
            border-radius: 4px;
            opacity: 0.5;
            text-indent: -9999em;
          }
          .main .section8 .slider .slick-dots li.slick-active button {
            opacity: 1;
          }
          .slick-dots li button:before {
            visibility: hidden;
          }
          .main .section .contact .location .infoText {
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: start;
          }
          /* Join Us */
          .joinus .section1 {
            padding: 30px 20px;
            background: #f3f5f7;
          }
          .joinus .section1 h3 {
            margin-bottom: 25px;
            font-size: 20px;
            font-weight: 300;
            color: #333;
            text-align: center;
          }
          .joinus .section1 h3 em {
            position: relative;
            z-index: 2;
          }
          .joinus .section1 h3 span {
            display: inline-block;
            position: relative;
          }
          .joinus .section1 h3 span:before {
            content: "";
            position: absolute;
            bottom: 2px;
            left: -5px;
            right: -5px;
            height: 4px;
            background: #fe6b37;
            opacity: 0.7;
          }
          .joinus .section1 .price-area .title {
            position: relative;
            margin-bottom: 20px;
            padding-right: 160px;
          }
          .joinus .section1 .price-area .title strong {
            display: block;
            font-size: 16px;
            font-weight: bold;
            color: #333;
            line-height: 20px;
          }
          .joinus .section1 .price-area .title span {
            display: block;
            font-size: 14px;
            font-weight: 300;
            color: #333;
            line-height: 20px;
          }
          .joinus .section1 .price-area .title .btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 160px;
            height: 40px;
            background: #333;
            border-radius: 2px;
            font-size: 12px;
            color: #fff;
            text-align: center;
            line-height: 40px;
          }
          .joinus .section1 .price-area .item + .item {
            margin-top: 40px;
          }
          .joinus .section1 .price-area .item.plus .title .btn {
            background: #fe6b37;
          }
          .joinus .section1 .price-area .row {
            display: flex;
            margin-bottom: 16px;
            background: #fff;
          }
          .joinus .section1 .price-area .row .col {
            position: relative;
            flex: 1 1 0;
            padding: 18px 0;
            text-align: center;
          }
          .joinus .section1 .price-area .row .col dt {
            display: table;
            width: 100%;
            height: 28px;
            margin-bottom: 13px;
          }
          .joinus .section1 .price-area .row .col dt .middle {
            display: table-cell;
            vertical-align: middle;
          }
          .joinus .section1 .price-area .row .col dt strong {
            display: block;
            font-size: 14px;
            font-weight: 500;
            color: #333;
            line-height: 12px;
          }
          .joinus .section1 .price-area .row .col dt span {
            display: block;
            margin-top: 4px;
            font-size: 12px;
            font-weight: 300;
            color: #fe6b37;
            line-height: 12px;
          }
          .joinus .section1 .price-area .row .col dd strong {
            display: block;
            font-size: 18px;
            font-weight: bold;
            color: #333;
            line-height: 27px;
          }
          .joinus .section1 .price-area .row .col dd span {
            display: block;
            font-size: 12px;
            font-weight: 300;
            color: #fe6b37;
            line-height: 12px;
          }
          .joinus .section1 .price-area .row .col:before {
            content: "";
            position: absolute;
            top: 10px;
            bottom: 12px;
            left: 0;
            width: 1px;
            background: #ddd;
          }
          .joinus .section1 .price-area .row .col:first-child:before {
            display: none;
          }
          .joinus .section1 .price-area .accordion .top {
            font-size: 0;
          }
          .joinus .section1 .price-area .accordion .top strong {
            display: inline-block;
            margin: 0 4px;
            font-size: 16px;
            font-weight: bold;
            color: #333;
            line-height: 22px;
            vertical-align: top;
          }
          .joinus .section1 .price-area .accordion .top span {
            display: inline-block;
            font-size: 16px;
            font-weight: 300;
            color: #333;
            line-height: 22px;
            vertical-align: top;
          }
          .joinus .section1 .price-area .accordion .top .btn {
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-left: 3px;
            background: url("/public/assets/mobile/images/icon/icon_down1.svg")
              no-repeat center center;
            text-indent: -9999em;
            vertical-align: top;
          }
          .joinus .section1 .price-area .accordion .bottom {
            display: none;
            margin-top: 7px;
            padding: 15px 20px 20px;
            background: #fff;
          }
          .joinus .section1 .price-area .accordion .bottom .tit {
            display: block;
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: 300;
            color: #333;
            line-height: 22px;
            text-align: center;
          }
          .joinus .section1 .price-area .accordion.on .top .btn {
            background-images: url("/public/assets/mobile/images/icon/icon_up1.svg");
          }
          .joinus .section1 .price-area .accordion.on .bottom {
            display: block;
          }
          .joinus .section1 .notice-area {
            margin-top: 40px;
            padding: 15px 20px 20px;
            background: #fff;
          }
          .joinus .section1 .notice-area h4 {
            margin-bottom: 9px;
            font-size: 14px;
            font-weight: bold;
            color: #333;
            text-align: center;
            line-height: 22px;
          }

          /* Sign Up */
          .signup {
            padding: 80px 30px 50px !important;
          }
          .signup .section + .section {
            margin-top: 30px;
          }
          .signup .section h3 {
            margin-bottom: 18px;
            font-size: 20px;
            font-weight: bold;
            color: #333;
            line-height: 29px;
          }
          .signup .section h3 span {
            display: inline-block;
            margin-left: 6px;
            font-size: 14px;
            font-weight: normal;
            color: #fe4a1c;
            line-height: 29px;
            vertical-align: top;
          }
          .signup .section .item_input_msg_hidden {
            margin: 0;
            padding: 3px 0;
          }
          .signup .section1 h3 {
            margin-bottom: 15px;
            font-size: 24px;
            line-height: 36px;
          }
          .signup .section1 p {
            font-size: 16px;
            color: #333;
            line-height: 1.6;
          }
          .signup .section2 p {
            margin-bottom: 10px;
            font-size: 16px;
            color: #333;
            line-height: 1.6;
            word-break: keep-all;
            word-wrap: break-word;
          }
          .signup .section2 .row {
            padding-left: 10px;
          }
          .signup .section2 .row + .row {
            margin-top: 20px;
          }
          .signup .section2 .row .tit {
            display: block;
            margin-left: -10px;
            font-size: 16px;
            font-weight: bold;
            color: #333;
            line-height: 40px;
          }
          .signup .section2 .row .tit span {
            margin-right: 3px;
            color: #ff663f;
          }
          .signup .section2 .row .tit em {
            font-size: 12px;
            color: #999;
          }
          .signup .section2 .row label {
            display: block;
          }
          .signup .section2 .row label + label {
            margin-top: 7px;
          }
          .signup .section2 .row label.btn-input {
            position: relative;
            padding-right: 100px;
          }
          .signup .section2 .row label.btn-input .btn-confirm {
            position: absolute;
            top: 0;
            right: 0;
            width: 95px;
            height: 35px;
            background: #35206a;
            border-radius: 3px;
            font-size: 14px;
            font-weight: bold;
            color: #fff;
          }
          .signup .section2 .row p {
            margin-top: 7px;
            font-size: 14px;
            font-weight: 500;
            color: #333;
          }
          .signup .section2 .row select {
            display: block;
            width: 100%;
            height: 35px;
            padding: 0 20px 0 10px;
            background: url("/public/assets/mobile/images/icon/icon_down2.svg")
              no-repeat right 12px center;
            border: 1px solid #979797;
            font-size: 16px;
            color: #333;
          }
          .signup .section2 input[type="text"],
          .signup .section2 input[type="password"] {
            display: block;
            width: 100%;
            height: 35px;
            padding: 0 10px;
            border: 1px solid #979797;
            font-size: 16px;
            color: #333;
          }
          .signup .section2 input[type="text"]::placeholder,
          .signup .section2 input[type="password"]::placeholder {
            color: #999;
          }
          .signup .section2 input:read-only {
            background: #f2f2f2;
            color: #999;
          }
          .signup .section2 .btn-question {
            margin: 5px 0;
            padding-left: 20px;
            background: url("/public/assets/mobile/images/icon/icon_question1.webp")
              no-repeat left center;
            background-size: 15px 15px;
            font-size: 14px;
            color: #333;
            line-height: 18px;
            text-decoration: underline;
          }
          .signup .section2 .detail-toggle {
            display: none;
            margin-top: 7px;
          }
          .signup .section2 .detail-toggle .route {
            display: none;
          }
          .signup .section2 .detail-toggle.on {
            display: block;
          }
          .signup .section2 .detail-toggle .route.on {
            display: block;
          }
          .signup .section2 .certify-input {
            position: relative;
          }
          .signup .section2 .certify-input input {
            padding-right: 45px;
          }
          .signup .section2 .certify-input .count {
            position: absolute;
            top: 0;
            right: 110px;
            font-size: 14px;
            color: #fe4a1c;
            line-height: 35px;
          }
          .signup .section3 .row {
            position: relative;
          }
          .signup .section3 .row + .row {
            margin-top: 20px;
          }
          .signup .section3 .btn-detail {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 12px;
            color: #999;
            text-decoration: underline;
            line-height: 20px;
          }
          .signup .btn-wrap {
            display: flex;
            margin-top: 48px;
          }
          .signup .btn-wrap .btn-cancel {
            flex: 0 1 113px;
            height: 45px;
            padding-top: 11px;
            background: #35206a;
            border-radius: 3px;
            font-size: 14px;
            font-weight: bold;
            color: #fff;
            text-align: center;
            line-height: 20px;
          }
          .signup .btn-wrap .btn-confirm {
            flex: 1 1 0;
            height: 45px;
            margin-left: 11px;
            padding-top: 11px;
            background: #ff663f;
            border-radius: 3px;
            font-size: 14px;
            font-weight: bold;
            color: #fff;
            text-align: center;
            line-height: 20px;
          }
          .signup .btn-wrap .btn-confirm.disabled {
            background: #ccc;
            color: #fff;
          }

          /* 서비스 소개 */
          .service-infomation .section1 {
            height: 150px;
          }
          .service-infomation .section1 dl {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            text-align: center;
          }
          .service-infomation .section1 dl dt {
            margin-bottom: 10px;
            font-size: 22px;
            font-weight: bold;
            color: #000;
            line-height: 28px;
          }
          .service-infomation .section1 dl dd {
            font-size: 15px;
            color: #000;
            line-height: 18px;
            letter-spacing: -1.5px;
          }
          .service-infomation .section2 {
            padding: 35px 20px 50px 20px;
          }
          .service-infomation .section2 h3 {
            margin-bottom: 40px;
            font-size: 22px;
            font-weight: bold;
            color: #333;
            text-align: center;
            line-height: 28px;
            letter-spacing: -2.35px;
          }
          .service-infomation .section2 .shadow-box {
            padding: 30px 0 0;
            background: #fff;
            border-radius: 10px;
            box-shadow: 2px 4px 10px 0 rgba(38, 29, 72, 0.25);
          }
          .service-infomation .section2 .shadow-box + .shadow-box {
            margin-top: 30px;
          }
          .service-infomation .section2 .shadow-box dl dt {
            margin-bottom: 12px;
            font-size: 20px;
            font-weight: 500;
            color: #000;
            text-align: center;
            line-height: 26px;
            letter-spacing: -2.5px;
          }
          .service-infomation .section2 .shadow-box dl dd {
            font-size: 15px;
            text-align: center;
            line-height: 20px;
            letter-spacing: -1.5px;
          }
          .service-infomation .section2 .shadow-box img {
            display: block;
            width: 100%;
            margin: 0 auto;
          }
          .service-infomation .section3 {
            padding: 40px 30px 60px;
            background: #f3f5f9;
          }
          .service-infomation .section3 h3 {
            margin-bottom: 40px;
            font-size: 22px;
            font-weight: bold;
            color: #000;
            text-align: center;
            line-height: 28px;
            letter-spacing: -2.15px;
          }
          .service-infomation .section3 .white-box {
            background: #fff;
            border-radius: 10px;
          }
          .service-infomation .section3 .white-box + .white-box {
            margin-top: 30px;
          }
          .service-infomation .section3 .white-box h4 {
            height: 49px;
            border-radius: 10px 10px 0 0;
            font-size: 20px;
            font-weight: 500;
            color: #fff;
            background: #46356B;
            text-align: center;
            line-height: 49px;
          }
          .service-infomation .section3 .white-box .row {
            display: flex;
            align-items: flex-start;
            padding: 16px 12px 16px 15px;
          }
          .service-infomation .section3 .white-box .row .col {
            flex: 1 1 0;
          }
          .service-infomation .section3 .white-box .row .col + .col {
            margin-left: 12px;
          }
          .service-infomation .section3 .white-box ul li {
            position: relative;
            padding-left: 9px;
            font-size: 14px;
            color: #000;
            text-align: left;
            letter-spacing: -1.4px;
            line-height: 24px;
            word-break: keep-all;
            word-wrap: break-word;
          }
          .service-infomation .section3 .white-box ul li:before {
            content: "";
            position: absolute;
            top: 11px;
            left: 0;
            width: 3px;
            height: 3px;
            background: #333;
            border-radius: 3px;
          }
          .service-infomation .section3 .white-box h5 {
            font-size: 14px;
            font-weight: 500;
            color: #fe6b37;
            line-height: 24px;
            letter-spacing: -1.4px;
          }
          .service-infomation .section3 .btn-start {
            display: block;
            width: 100%;
            margin-top: 35px;
            padding: 16px 30px 15px;
            background: url("/public/assets/mobile/images/icon/icon_move_link1.svg")
              no-repeat right 30px center #fe6b37;
            border-radius: 10px;
            box-shadow: 2px 4px 10px 0 rgba(38, 29, 72, 0.25);
          }
          .service-infomation .section3 .btn-start strong {
            display: block;
            margin-bottom: 1px;
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            line-height: 24px;
          }
          .service-infomation .section3 .btn-start span {
            display: block;
            font-size: 15px;
            font-weight: 500;
            color: #fff;
            line-height: 24px;
          }
          .service-infomation .section4 {
            padding: 35px 32px 50px;
          }
          .service-infomation .section4 .row + .row {
            margin-top: 30px;
          }
          .service-infomation .section4 .row h3 {
            margin-bottom: 20px;
            font-size: 22px;
            font-weight: bold;
            color: #000;
            text-align: center;
            line-height: 28px;
          }
          .service-infomation .section4 .row .color-box {
            padding: 25px 38px 40px;
            border-radius: 10px;
            box-shadow: 2px 4px 10px 0 rgba(38, 29, 72, 0.25);
          }
          .service-infomation .section4 .row .color-box.type1 {
            background: #697db7;
          }
          .service-infomation .section4 .row .color-box.type2 {
            background: #fe6b37;
          }
          .service-infomation .section4 .row .color-box h4 {
            margin-bottom: 20px;
            font-size: 20px;
            font-weight: 500;
            color: #fff;
            text-align: center;
            line-height: 24px;
          }
          .service-infomation .section4 .row .color-box a {
            display: block;
            width: 100%;
            padding: 9px 0 10px;
            background: #fff;
            border-radius: 50px;
            font-size: 14px;
            font-weight: bold;
            color: #000;
            text-align: center;
            line-height: 18px;
            letter-spacing: -1px;
          }

          .service-marketbom .section1 {
          }
          .service-marketbom .section2 .shadow-box.type1 {
            padding-bottom: 20px;
          }
          .service-marketbom .section2 .shadow-box.type1 img {
            margin: 16px auto 0;
          }
          .service-marketbom .section2 .shadow-box.type2 {
            padding-bottom: 20px;
          }
          .service-marketbom .section2 .shadow-box.type2 img {
            margin: 16px auto 0;
          }
          .service-marketbom .section2 .shadow-box.type3 {
            padding-bottom: 17px;
          }
          .service-marketbom .section2 .shadow-box.type3 img {
            margin: 16px auto 0;
          }
          .service-marketbom .section2 .shadow-box.type4 {
            padding-bottom: 27px;
          }
          .service-marketbom .section2 .shadow-box.type4 img {
            margin: 24px auto 0;
          }
          .service-marketbom .section2 .shadow-box.type5 {
            padding-bottom: 25px;
          }
          .service-marketbom .section2 .shadow-box.type5 img {
            margin: 23px auto 0;
          }
          .service-marketbomdome .section1 {
            background: url("/public/assets/mobile/images/pic/img_marketbomdome_section1.webp")
              no-repeat center center;
          }
          .service-marketbomdome .section2 .shadow-box.type1 {
            padding-bottom: 15px;
          }
          .service-marketbomdome .section2 .shadow-box.type1 img {
            margin: 15px auto 0;
          }
          .service-marketbomdome .section2 .shadow-box.type2 {
            padding-bottom: 32px;
          }
          .service-marketbomdome .section2 .shadow-box.type2 img {
            margin: 25px auto 0;
          }
          .service-marketbomdome .section2 .shadow-box.type3 {
            padding-bottom: 22px;
          }
          .service-marketbomdome .section2 .shadow-box.type3 img {
            margin: 26px auto 0;
          }
          .service-marketbomdome .section2 .shadow-box.type4 {
            padding-bottom: 16px;
          }
          .service-marketbomdome .section2 .shadow-box.type4 img {
            margin: 24px auto 0;
          }
          .service-marketbomdome .section2 .shadow-box.type5 {
            padding-bottom: 20px;
          }
          .service-marketbomdome .section2 .shadow-box.type5 img {
            margin: 16px auto 0;
          }
          .service-marketbomdome .section2 .shadow-box.type6 {
            padding-bottom: 22px;
          }
          .service-marketbomdome .section2 .shadow-box.type6 img {
            margin: 20px auto 0;
          }
          .service-marketbomdome .section3 {
            padding-bottom: 50px;
          }
          .service-marketbomdome .section3 .white-box.type1 h4 {
            background: #d5f2ef;
          }
          .service-marketbomdome .section3 .white-box.type2 h4 {
            background: #d4e8f1;
          }
          .service-marketbomdome .section3 .white-box.type3 h4 {
            background: #d4e1f3;
          }
          .service-marketbomdome .section3 .white-box.type4 h4 {
            background: #dbdaf3;
          }
          .service-marketbomdome .section3 .white-box.type5 h4 {
            background: #eddcf4;
          }

          /* 이용가이드 */
          .usage-guide .section1 {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 195px;
          }
          .usage-guide .section1 h3 {
            font-size: 30px;
            margin-bottom: 10px;
            color: #fff;
            line-height: 1.2;
            text-align: center;
          }
          .usage-guide .section-header {
            background: #332c51;
          }
          .usage-guide .section1 p {
            font-size: 16px;
            text-align: center;
            color: #fff;
            line-height: 22px;
            letter-spacing: -1.12px;
          }
          .usage-guide .section2 .menu-wrap {
            overflow-x: auto;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
          }
          .usage-guide .section2 .menu-wrap ul {
            display: block;
            font-size: 0;
            white-space: nowrap;
          }
          .usage-guide .section2 .menu-wrap ul li {
            display: inline-block;
          }
          .usage-guide .section2 .menu-wrap ul li a {
            display: block;
            height: 52px;
            padding: 18px 13px 0;
            font-size: 16px;
            font-weight: bold;
            color: #000;
            line-height: 1;
            opacity: 0.5;
          }
          .usage-guide .section2 .menu-wrap ul li.on a {
            border-bottom: 4px solid #fe6b37;
            opacity: 1;
          }
          .usage-guide .section2 .menu-wrap ul li:first-child {
            margin-left: 20px;
          }
          .usage-guide .section2 .menu-wrap ul li:last-child {
            margin-right: 20px;
          }

          .usage-guide .section2 .content .menu-cont {
            display: block;
          }
          .usage-guide .section2 .menu-cont ul {
            padding: 40px 40px 40px;
          }
          .usage-guide .section2 .menu-cont ul li + li {
            margin-top: 30px;
          }
          .usage-guide .section2 .menu-cont ul h4 {
            margin-bottom: 5px;
            font-size: 18px;
            font-weight: bold;
            color: #000;
            line-height: 24px;
            letter-spacing: -.5px;
          }
          .usage-guide .section2 .menu-cont ul p {
            font-size: 15px;
            color: #555;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: -1.5px;
          }
          .usage-guide .section2 .menu-cont ul h3 {
            margin-bottom: 10px;
            font-size: 15px;
            font-weight: bold;
            line-height: 22px;
          }
          .usage-guide .section2 .menu-cont .photo-section ul {
            padding: 0 40px 50px;
          }
          .usage-guide .section2 .menu-cont .photo-section ul li + li {
            margin-top: 15px;
          }
          .usage-guide .section2 .menu-cont .photo-section img {
            border: 1px solid #999;
            border-radius: 12px;
          }
          .usage-guide .section2 .menu-cont .photo-section h3 {
            margin-bottom: 10px;
            font-size: 15px;
            font-weight: 700;
            line-height: 22px;
          }
          .usage-guide .section3 .promotion-area {
            width: 100%;
            height: 90px;
            text-align: center;
            padding-top: 30px;
            font-size: 20px;
            font-weight: 900;
            color: #fff;  
            line-height: 36px;
          }
          .usage-guide .section3 .promotion-area strong {
            color: #ffee31;  
          }
          /* =================================================
  * Footer
================================================= */
          #footer {
            padding: 30px;
            background: #242426;
          }
          #footer .tit {
            margin-bottom: 3px;
            font-size: 12px;
            color: #fff;
            line-height: 18px;
          }
          #footer .copyright {
            margin-bottom: 20px;
          }
          #footer .copyright p {
            line-height: 15px;
          }
          #footer .copyright p br {
            content: "";
            display: block;
            height: 0;
            margin: 0;
          }
          #footer .copyright p + p {
            margin-top: 5px;
          }
          #footer .copyright p + p + p {
            margin-top: 24px;
          }
          #footer .copyright p span {
            display: inline-block;
            font-size: 12px;
            font-weight: 100;
            color: #fff;
            line-height: 15px;
          }
          #footer .copyright p span + span {
            margin-left: 5px;
          }
          #footer .sns-app .sns {
            display: flex;
            align-items: center;
            margin-bottom: 19px;
            font-size: 0;
          }
          #footer .sns-app .sns a {
            flex: 0 1 auto;
            width: 22px;
            height: 22px;
          }
          #footer .sns-app .sns .btn-youtube {
            margin-left: 20px;
            /* background: url('/public/assets/mobile/images/icon/icon_youtube1.svg') no-repeat center top; */
            text-indent: -9999em;
            vertical-align: top;
          }
          #footer .sns-app .sns .btn-blog {
            /* background: url('/public/assets/mobile/images/icon/icon_blog1.svg') no-repeat center top; */
            text-indent: -9999em;
            vertical-align: top;
          }
          #footer .sns-app .sns .btn-remote-control {
            width: 28px;
            height: 28px;
            margin-left: auto;
            /* background: url('/public/assets/mobile/images/icon/icon_computer1.svg') no-repeat center center; */
            background-size: 100% 100%;
          }
          #footer .sns-app .app-download {
            margin: 0 5px;
            padding-top: 14px;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
          }
          #footer .sns-app .app-download:after {
            content: "";
            display: block;
            clear: both;
          }
          #footer .sns-app .app-download a {
            float: left;
            width: calc(50% - 5px);
            height: 50px;
            text-indent: -9999em;
          }

          #footer .sns-app .app-download .btn-appstore {
            margin-left: 10px;
          }
        `}
      </style>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: -1,
        }}
      >
        <div
          style={{
            background: "white",
            width: "100%",
            height: "60%",
          }}
        ></div>
        <div
          style={{
            height: "100px",
            width: "100%",
            backgroundImage: `url(${배경_이미지.src})`,
            backgroundSize: "contain",
            top: "50%",
          }}
        />
      </div>
      <div className={"whole-wrapper"}>
        <div className={"mobile-description"}>
          <마켓봄_로고_Desktop />
          <div style={{ fontSize: "24px", marginBottom: "16px" }}>
            식자재 유통은 <strong className="bold">마켓봄</strong>
          </div>
          <div>
            이제 식자재 유통도 온라인 시대!
            <br />
            언제 어디서나 쉽고 편리한 마켓봄으로
            <br />
            <span className={"fc1 bold"}>지금 바로 시작하세요!</span>
          </div>
        </div>

        <div className={"device-container"}>
          <div className={"content-container"}>{children}</div>
        </div>
      </div>
    </>
  );
};
